.markdown-context {
    text-align: left;
    font-size: 1em;
    letter-spacing: 0em;
    padding: 18px 14px;
    --tw-text-opacity: 0.2;

    strong {
        font-weight: 700;
        padding: 1px 3px;
        color: #a27cd0;
    }

    ul,
    ol {
        list-style-type: disc;
        padding-bottom: 12px;
        margin-top: -12px;
        li {
            margin: 0px;
            padding: 0px;
            padding-bottom: 0px;
            margin-bottom: 4px;
            margin-left: 2em;

            p {
                margin-bottom: 0px;
            }
        }
        ::marker {
            color: #6b5488;
            font-size: 1rem;
        }
    }

    h1,
    h2,
    h3 {
        margin: 22px 0 0.3em;
        color: #c5a1f6;
    }


    h1 + hr,
    h2 + hr,
    h3 + hr  {
        margin-bottom: 16px;
    }

    h1 {
        font-size: 48px;
        font-weight: 800;
    }

    h2 {
        font-size: 30px;
        font-weight: 700;
    }

    h3 {
        font-size: 24px;
        font-weight: 600;
    }

    p {
        margin: 0 0 0.3em;
        line-height: 1.4rem;
        text-align: left;
    }

    blockquote {
        background-color: #faf5ff;
        border: 2px solid #dcd2e9;
        border-radius: 4px;
        padding: 4px 20px;
        margin-bottom: 24px;
        :before {
            color: #eee8f6;
            content: open-quote;
            font-size: 3.2em;
            line-height: 0.1em;
            margin-right: 0.1em;
            vertical-align: -0.4em;
        }
    }
    blockquote p {
        color: #6b5488;
        margin: 6px 0;
        font-weight: 400;
        padding-bottom: 0px;
    }

    @media only screen and (max-width: 768px) {
        .markdown-context {
            padding: 12px 6px;

            ul,
            ol {
                list-style-type: disc;
                padding-bottom: 12px;
                li {
                    margin: 0px;
                    padding: 0px;
                    padding-bottom: 0px;
                    margin-bottom: 4px;
                    margin-left: 0.6em;

                    p {
                        margin-bottom: 0px;
                    }
                }
                ::marker {
                    color: #6b5488;
                    font-size: 1rem;
                }
            }
        }
    }
}
