@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;800;900&display=swap");

@tailwind base;

@layer base {
  h2,
  h3,
  h4 {
    @apply pt-2;
    @apply pb-2;
  }
  /* @apply text-gpas-800; */
  h1 {
    @apply text-5xl;
    @apply font-bold;
  }
  /* @apply text-gpas-800; */
  h2 {
    @apply text-2xl;
    @apply font-semibold;
  }
  /* @apply text-gpas-700; */
  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-lg;
  }
  a {
    @apply text-gpas-700;
    @apply no-underline;
  }
  p {
    @apply pb-2;
  }
}

@tailwind components;

@tailwind utilities;

html {
  font-family: "Montserrat" sans-serif !important;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: none;
  line-height: 1.4em;
}

body {
  background-color: #f5f6f8 !important;
}

@-webkit-keyframes test {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bg-title:hover .child,
.bg-title:focus .child {
  transform: scale(1.01);
}

.bg-title:hover .child:before,
.bg-title:focus .child:before {
  display: block;
}

.bg-title {
  -webkit-animation-name: test;
  -webkit-animation-duration: 0.2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
}
.bg-title .child {
  overflow-x: hidden;
  transition: all .2s ease-in-out;
}

.bg-title .child::before {
  content: "";
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* .player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
} */



@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}